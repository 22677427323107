import strings from "../../strings";
import "./common/CommonStyles.css";
import { usePrismicDocumentByUID } from "@prismicio/react";
import { useParams } from "react-router-dom";

const ContentTask = () => {
  const { titleSocialTasks, subSocialTasks, subSafeAndEasy } =
    strings.socialView;

  let { uid } = useParams();
  const [campaign, { state }] = usePrismicDocumentByUID("campaign", `${uid}`);

  return (
    <>
      {state === "loading" ? (
        <p>Loading…</p>
      ) : (
        campaign && (
          <div className="content-info-container">
            <div className="task-container">
              <p className="title-task">{titleSocialTasks}</p>
              <div className="border-content"></div>
              <p className="user-info">{subSocialTasks}</p>
              <p>{subSafeAndEasy}</p>
              <div className="car-price">
                <p className="row-car">{campaign.data.product}</p>
                <p className="price-year">{campaign.data.price} kr/år</p>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ContentTask;
